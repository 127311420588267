import React from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageFooter from "../components/PageFooter"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <>
    <SEO title={"Work | PanTum Detect"} slug={"pantum-detect"} />

    <Row className="white-container case-study">
      <Container className="mt-5 pt-5">
        <Row className="mt-5 pt-5 no-gutters">
          <Col sm="8" className="super-margin">
            <h1>
              PanTum Detect<sup>®</sup> Customer Portal for Multi-Cancer Early
              Detection Test
            </h1>
            <br />
            <p>Case Study</p>
          </Col>
        </Row>
      </Container>
    </Row>

    <Row className="case-study-content">
      <Container
        className="hero-container"
        style={{
          background: "#24405a",
          marginBottom: 20,
        }}
        fluid
        tag="section"
      >
        <StaticImage
          src="../images/pantum/web_1.png"
          alt="web_1"
          width={1600}
          quality={100}
          formats={["auto", "webp"]}
        />
      </Container>

      <Container className="article-content" tag="section" fluid>
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="6" className="p-0 section-text float-left">
                <h3>01 Challenge</h3>
                <p>
                  PanTum Detect<sup>®</sup> is a screening test, designed to
                  identify tumor-independent, cancerous immunological processes,
                  invented and developed by Zyagnum AG, a biotechnology firm
                  headquartered in Darmstadt, Germany.
                  <br />
                  <br />
                  Our primary mission encompassed the design and development of
                  a <span className="highlighted">Customer Portal</span> that
                  would serve as the linchpin of the PanTum Detect
                  <sup>®</sup> ecosystem, seamlessly unifying an array of
                  customer-centric services. Its core functions include
                  facilitating the scheduling of blood draw appointments at
                  numerous locations throughout Germany, delivering prompt test
                  results, and enabling the initiation of telemedicine
                  consultations and supplementary screenings (such as PSA tests,
                  MRI/PET-CT scans) when deemed necessary.
                  <br />
                  <br />
                  Moreover, a suite of custom tools and software solutions was
                  indispensable for streamlining daily back-office operations.
                  These encompassed vital tasks like location and availability
                  management, customer support, and accounting.
                  <br />
                  <br />
                  To ensure a seamless user experience, it was essential to
                  integrate a wide range of services, including the insurance
                  provider, laboratories, and telemedicine solutions.
                  <br />
                  <br />
                </p>
              </Col>
              <Col
                sm={{ size: 4, offset: 2 }}
                className="p-0 section-text float-left"
              >
                <h4>Scope</h4>
                <p className="gray">
                  Product design / UX & UI / Process modeling / System
                  Architecture / Frontend / Backend / APIs / Cloud Architecture
                  / Team & Project Management
                </p>
                <h4>Date</h4>
                <p className="gray">2020 - 2023</p>
                <h4>Client & location</h4>
                <p className="gray">Zyagnum AG / Darmstadt, Germany</p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="article-content"
        tag="section"
        fluid
        style={{
          background: "#24405a",
          color: "#fff",
        }}
      >
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="6" className="p-0 section-text float-left">
                <h3>02 Steering the Project</h3>
                <p>
                  My involvement in this project began at its inception, where I
                  assumed a pivotal role in shaping the creation of a product.
                  As a proxy <span className="highlighted">Product Owner</span>{" "}
                  and <span className="highlighted">Technical Lead</span>, I led
                  the charge in all critical aspects of development, which
                  included business process mapping, user journeys, product
                  design, a comprehensive roadmap, and the software
                  architecture.
                  <br />
                  <br />
                  I assembled and led a dedicated team comprising software
                  developers, DevOps engineers and QA testers, ensuring the
                  delivery of exceptional software solutions.
                  <br />
                  <br />
                  Our journey through the product's development was anchored in
                  the principles of Scrum methodology. We commenced with
                  prototyping, navigated through a beta-phase, and welcomed our
                  first users in 2021. By the close of 2022, we successfully
                  launched a fully realized product. Throughout this voyage, we
                  meticulously incorporated user feedback to refine and enhance
                  key processes. Today, the Customer Portal stands as a robust
                  platform that serves numerous locations throughout Germany,
                  assisting hundreds of customers daily.
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container tag="section" className="mobile-screens-container">
        <Row>
          <Col xs="12" sm="4">
            <Container
              className="imagery-content mobile-imagery"
              style={{ background: "#eff3f6" }}
              fluid
              tag="section"
            >
              <StaticImage
                src="../images/pantum/mobile_2.png"
                alt="mobile_1"
                width={1200}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Container>
          </Col>
          <Col xs="12" sm="4">
            <Container
              className="imagery-content mobile-imagery"
              style={{ background: "#eff3f6" }}
              fluid
              tag="section"
            >
              <StaticImage
                src="../images/pantum/mobile_3.png"
                alt="mobile_3"
                width={1200}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Container>
          </Col>
          <Col xs="12" sm="4">
            <Container
              className="imagery-content mobile-imagery"
              style={{ background: "#eff3f6" }}
              fluid
              tag="section"
            >
              <StaticImage
                src="../images/pantum/mobile_1.png"
                alt="mobile_1"
                width={1200}
                quality={100}
                formats={["auto", "webp"]}
              />
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="article-content" tag="section" fluid>
        <Container>
          <Row className="inter-section">
            <Col sm="12">
              <Col sm="4" className="p-0 section-text float-left">
                <div className="pr-5">
                  <h3>03 Solutions</h3>
                  <p className="section-intro">
                    Customer Portal has been designed as a one-stop platform for
                    an array of essential services
                  </p>
                </div>
              </Col>
              <Col sm="4" className="p-0 section-text float-left">
                <div className="module-desc">
                  <h4>Appointments</h4>
                  <p className="gray">
                    Customers can select a nearby medical center and
                    effortlessly schedule their blood draw appointment
                  </p>
                  <br />
                  <h4>Test Status and Findings</h4>
                  <p className="gray">
                    System makes sure that customers stay informed as their
                    blood sample undergoes analysis, and receive report promptly
                    upon completion
                  </p>
                  <br />
                  <h4>Telemedicine</h4>
                  <p className="gray">
                    User may effortlessly schedule a consultation with a doctor
                    to discuss findings and explore potential courses of action
                  </p>
                </div>
              </Col>
              <Col sm="4" className="p-0 section-text float-left">
                <div className="module-desc">
                  <h4>PSA, MRI/PET-CT</h4>
                  <p className="gray">
                    System supports in-depth diagnostics with imaging scans and
                    keeps customers informed with regular status updates
                  </p>
                  <br />
                  <h4>Track of Records</h4>
                  <p className="gray">
                    Comprehensive history of appointments, tests, and orders,
                    enabling customers to stay informed about their healthcare
                    journey
                  </p>
                  <br />
                  <h4>Diagnostic Process</h4>
                  <p className="gray">
                    Seamless guidance through every facet of the diagnostic
                    process, providing timely notifications regarding critical
                    arrangements
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="imagery-content last-imagery"
        style={{
          background: "#eff3f6",
          color: "#24405a",
          marginBottom: 20,
        }}
        fluid
        tag="section"
      >
        <Container>
          <h3 className="center mb-5">Appointment Scheduling</h3>
        </Container>
        <div className="work-image center">
          <StaticImage
            src="../images/pantum/web_2.png"
            alt="web_3"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
        <div className="work-image center">
          <StaticImage
            src="../images/pantum/web_3.png"
            alt="web_3"
            width={1600}
            quality={100}
            formats={["auto", "webp"]}
          />
        </div>
      </Container>
      <Container
        className="article-content"
        tag="section"
        fluid
        style={{ backgroundColor: "#24405a", color: "#fff", marginBottom: 30 }}
      >
        <Container>
          <Row className="inter-section pb-0">
            <Col sm="12" className="intro-image">
              <Col
                sm="5"
                className="p-0 section-text float-left"
                style={{ alignItems: "center", display: "flex" }}
              >
                <div className="pr-5">
                  <h3>04 Processes Covered</h3>
                  <p className="section-intro-light">
                    The Customer Portal comes equipped with a suite of solutions
                    and tools to streamline daily operations
                  </p>
                </div>
              </Col>
              <Col sm="7">
                <Container
                  className=""
                  style={{ background: "" }}
                  fluid
                  tag="section"
                >
                  <StaticImage
                    src="../images/pantum/process_2.png"
                    alt="web_3"
                    width={1300}
                    quality={100}
                    formats={["auto", "webp"]}
                  />
                </Container>
              </Col>
            </Col>
          </Row>
          <Row className="inter-section mb-5 pb-3">
            <Col sm="12">
              <Col sm="4" className="p-0 section-text float-left">
                <div className="module-desc">
                  <h4>Scheduling Management </h4>
                  <p>
                    Dedicated platform to manage availability of numerous
                    locations dispersed throughout Germany
                  </p>
                  <br />
                  <h4>Backoffice Tools</h4>
                  <p>
                    Designed to empower admins, enabling streamlined daily tasks
                    encompassing platform management
                  </p>
                </div>
              </Col>
              <Col sm="4" className="p-0 section-text float-left">
                <div className="module-desc">
                  <h4>Medical Staff Dashboard</h4>
                  <p>
                    Facilitating seamless management of blood draw processes,
                    enhancing efficiency
                  </p>
                  <br />
                  <h4>Customer Service Tools</h4>
                  <p>
                    Set of tools empowering the Customer Service Team to manage
                    customers, orders and appointments
                  </p>
                </div>
              </Col>
              <Col sm="4" className="p-0 section-text float-left">
                <div className="module-desc">
                  <h4>Billing System</h4>
                  <p>
                    Helping accountants with key operations, including orders
                    and invoice management
                  </p>
                  <br />
                  <h4>External Vendors</h4>
                  <p>
                    Integration with external solutions essential to the
                    Customer Portal daily operations
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        className="last-imagery container-no-padding"
        fluid
        tag="section"
      >
        <div className="circles">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Row>
          <Col sm="6">
            <div
              className="imagery-content-xs last-imagery-xs pr-4 pl-4"
              style={{
                background: "#fff",
                color: "#24405a",
                marginBottom: 20,
              }}
            >
              <h4 className="mb-4 center">Check-in Process</h4>
              <StaticImage
                src="../images/pantum/process_1.png"
                alt="web_3"
                width={1300}
                quality={100}
                formats={["auto", "webp"]}
              />
            </div>
          </Col>
          <Col sm="6">
            <div
              className="imagery-content-xs last-imagery-xs pr-4 pl-4"
              style={{
                background: "#fff",
                color: "#24405a",
                marginBottom: 20,
              }}
            >
              <h4 className="mb-4 center">Check-in Overview</h4>
              <StaticImage
                src="../images/pantum/web_4.png"
                alt="web_3"
                width={1300}
                quality={100}
                formats={["auto", "webp"]}
              />
            </div>
          </Col>
        </Row>
        <Container className="pt-5">
          <Row align="center" className="mt-5">
            <Col sm="4">
              <h2>10</h2>
              <p>Team Members</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>2.5 yrs</h2>
              <p>Duration</p>
            </Col>
            <Col xs="6" sm="4">
              <h2>Sep. '22</h2>
              <p>Launch Date</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
    <WipeScreen />
    <PageFooter project="biologica" />
  </>
)

SecondPage.Layout = Layout
export default SecondPage
